import React from 'react'
import Footer from '../../components/Footer/Footer'
import img1 from './IMG_7698 (1).jpg'
import "./mahaprabhu.css"
import img2 from "./IMG-20220413-WA0015.jpg"
const Chaitnya = () => {
  return (
    <div className="mahaprabhu container">
      <div className="mahaprabhu__container">
        <div className="mahaprabhu__container__title">
          <h1>Shri Chaitnya Mahaprabhu</h1>
        </div>
        <div className="mahaprabhu__container__content">
          <div className="mahaprabhu__container__content__left text-white">
            <p className="text-white">
              Amiya nimai mahaprabhu Sri Sarvabhauma Madhusudan Goswami was a
              devoted in the discipleship of Srila Gopal Bhatt Goswami and a
              worshiper of Shri Radha Ramanji in Vrindavan.During his life, he
              with some other Goswamis traveled across India spreading the
              teachings of Sri Chaitanya Mahaprabhu. Madhusudan Goswami was
              completely dedicated to Sri Chaitanya Mahaprabhu. He and his
              disciples installed many deities of Mahaprabhu in different
              places. It is said that it was partly due to inspiration from Sri
              Madhusudan Goswami that Sri Shishir Kumar Ghosh wrote his famous
              books, Sri Amiya Nimai Charita in Bengali and Lord Gauranga in
              English. He is also credited with inspiration from Sri Krishna
              Gopal Duggal in his Urdu language book about Mahaprabhu’s life. In
              Nabadwip, Sri Madhusudan Goswami obtained a very old and
              life-sized deity from Mahaprabhu, who he called “Sri Amiya Nimai
              Gauranga Mahaprabhu”.
            </p>
            <div className="mahaprabhu__container__content__right">
              <img src={img1} alt="img1" className="image-mobile" />
            </div>

            <p className="text-white">
              By order of Sri Madhusudan, his disciple Sri Radha Govinda Goswami
              installed the deity in a temple in the Bagh Bazar district of
              Kolkata. Radha Govinda Goswami was born into an upper-class
              Brahman family, and his ancestors were great devotees of Chaitanya
              Mahaprabhu. Highly qualified musician, lived teaching music. What
              I earned, I spent at Mahaprabhu service. Radha Govinda Goswami
              didn’t see Amiya Nimai as a deity. In his dealings with the Lord,
              he saw and worshiped him as a living demonstration of Sri
              Chaitanya Mahaprabhu. When Radha Govinda Goswami began to grow
              old, he began to worry about the future cult of Amiya Nimai. There
              was no successor in his family. Once, in a condition of
              powerlessness, Sri Goswami Ji asked Mahaprabhu: ” In the future,
              how and for whom your service will be performed?” That night,
              Mahaprabhu came to him in a dream and said, ” Stop worrying. Take
              me to Vrindavan, where your guru’s son lives”. After receiving
              this order from the Lord, Sri Radha Govinda Goswami wrote a letter
              to the son of his guru, Sri Krishna Chaitanya Goswami. He
              explained the instructions Mahaprabhu had given him, and
              requested: Please make arrangements to take Amiya Nimai to
              Vrindavan “.
            </p>
            <div className="mahaprabhu__container__content__right">
              <img src={img2} alt="img1" className="image-mobile" />
            </div>
            <p className="text-white">
              In November 1936, a group of devotees including Sri Krishna
              Chaitanya Goswami, Sri Ramdas Baba Ji Maharaj from Path Bari and
              others took Amiya Nimai from Kolkata to Hathras in a reserved
              train coach was brought to Vrindavan by truck. When Sri Chaitanya
              Mahaprabhu originally came to Vrindavan, Kartik’s full moon day of
              the month came. On that day, November 26, 1926, Kartik’s full moon
              day, the deity of Sri Amiya Nimai Mahaprabhu arrived in Vrindavan
              AIl Vrindavan vaishnavas met and gave an unprecedented welcome
              with tumultuous kirtan. As there was no suitable place immediately
              available to install such a large deity of Mahaprabhu, Amiya Nimai
              was temporarily worshipped outside the main gate of Radha Raman
              Temple, in an area known as the raasmandal. Amiya Nimai stayed
              there for four or five years. Then, Sri Shah KS Gupta, the current
              server of Sri Shah Ji Mandir, Vrindavan and his grandmother
              Srimati Rama Devi, built two large rooms on the shore of the
              Yamuna in what was then the garden of Shah Ji temple, also known
              as Jugal Bagicha, and the deity was moved there. For about sixteen
              years, Amiya Nimai stayed there. As the years passed, Sri Krishna
              Chaitanya Goswami thought, ” Mahaprabhu must have his own temple
              where his worship can be carried out smoothly and regularly “. Sri
              Goswami owned a property in Gopinath Bazar, Vrindavan. There,
              around 1954, the construction of a temple for Amiya Nimai began.
              After the departure of Sri Krishna Chaitanya Goswami, her only
              son, Sri Vishwambhar Goswami, assumed the responsibilities of
              Amiya Nimai service. After Sri Vishwambhar Goswami, his children
              Sri Padmanabh Goswami and Sri Padmalochan Goswami continue the
              service sincerely.
            </p>
          </div>
          <div className="mahaprabhu__container__content__right">
            <img src={img1} alt="img1" className="image-pc" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Chaitnya