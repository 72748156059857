import React from "react";
import Footer from "../../components/Footer/Footer";
import about from "./ABOUT_US.jpg.JPG";
import "./About.css";
import img2 from "./IMG_9694.JPG";
import img3 from "./IMG_7698.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import img_gallery_1 from "./images/img1 (1).JPG";
import img_gallery_2 from "./images/img1 (2).JPG";
import img_gallery_3 from "./images/img1 (3).JPG";
import img_gallery_4 from "./images/img1 (4).JPG";
import img_gallery_5 from "./images/img1 (5).JPG";
import img_gallery_6 from "./images/img1 (6).JPG";
import img_gallery_7 from "./images/img1 (7).JPG";
import img_gallery_8 from "./images/img1 (8).JPG";
import img_gallery_9 from "./images/img1 (9).JPG";
import img_gallery_10 from "./images/img1 (10).JPG";
import img_gallery_11 from "./images/img1 (11).JPG";
import img_gallery_12 from "./images/img1 (12).JPG";
import img_gallery_13 from "./images/img1 (13).JPG";
import img_gallery_14 from "./images/img1 (14).JPG";
import img_gallery_15 from "./images/img1 (15).JPG";
import img_gallery_16 from "./images/img1 (16).JPG";
import img_gallery_17 from "./images/img1 (17).JPG";
import img_gallery_18 from "./images/img1 (18).JPG";
import img_gallery_19 from "./images/img1 (19).JPG";
import img_gallery_20 from "./images/img1 (20).JPG";
import img_gallery_21 from "./images/img1 (21).JPG";

const images = [
  img_gallery_1,
  img_gallery_2,
  img_gallery_3,
  img_gallery_4,
  img_gallery_5,
  img_gallery_6,
  img_gallery_7,
  img_gallery_8,
  img_gallery_9,
  img_gallery_10,
  img_gallery_11,
  img_gallery_12,
  img_gallery_13,
  img_gallery_14,
  img_gallery_15,
  img_gallery_16,
  img_gallery_17,
  img_gallery_18,
  img_gallery_19,
  img_gallery_20,
  img_gallery_21,
];

const SponsorCard = ({ img }) => {
  return (
    <div className=" card p-3 sponsor-card">
      <img loading="lazy" className="card-img" src={img} alt="" />
    </div>
  );
};

const Vrindavan = () => {
  const isMobile = window.innerWidth <= 576; // Check if the current screen size is mobile

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3, // Show 1 slide on mobile, 3 slides on larger screens
    swipeToSlide: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="vrindavan container align-center">
      <div className="vrindavan__middle__3rd  container ">
        <div className="vrindavan__middle__3rd__top">
          <h1 className="vrindavan__middle__3rd__top__heading pb-3">
            Vrindavan
          </h1>
          <div className="vrindavan__middle__3rd__top__img">
            <img loading="lazy" src={img2} alt="" />
          </div>
          <p className="vrindavan__middle__3rd__top__para-1 text-white">
            Vrindavan is just not a place but a feeling . A place where our
            beloved shri Radharaman lal played , grew up and had several past
            time Leelas . On every step we can feel him being with us .
            Everything from a little dust particle till the ancient temples are
            colored in the love for Krishna and depicting a past time of him .
            The trees , birds , The Yamuna maharani , a tiny kid till an old
            monk every one chants the pious name of shri Radha , the Praan of
            our blue cow heard boy . Vrindavan is considered to be one of the
            most important places of pilgrimage for the devotees of Krishna. It
            is said that Lord Krishna had spent his childhood in Vrindavan. The
            name of the city has been derived from Vrinda (meaning basil) and
            van (meaning grove) which perhaps refer to the two small groves at
            Nidhivan and Seva Kunj. Since Vrindavan is considered to be a sacred
            place, a large number of people come here to abandon their worldly
            life.
          </p>
          <div className="pc">
            <div className="pc-container d-flex align-items-center justify-content-center flex-column sponsor-container mt-5">
              <div className="row justify-content-center">
                {images.map((element, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-12 mb-3">
                    <SponsorCard img={element} />
                  </div>
                ))}
              </div>
            </div>
            <div className="container mobile-container sponsor-container my-5">
              <h1 className="heading mb-4">Gallery</h1>
              <div className="container_vrindavan">
                <Slider {...settings}>
                  {images.map((element, index) => (
                    <div key={index}>
                      <SponsorCard img={element} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Vrindavan;
