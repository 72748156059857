import React, { lazy, Suspense } from "react";

const LazyOffering = lazy(() => import("./Offering"));

const OfferingLazyLoader = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <LazyOffering />
  </Suspense>
);

export default OfferingLazyLoader;
