import React from "react";
import "./Donate.css";
import img1 from "./donate.jpg"
import Footer from "../../components/Footer/Footer";
const Donate = () => {
  return (
    <div className="Donate">
      <div className="Donate__container">
        <div className="Donate__container__heading">
          <h1>Donate Us</h1>
        </div>

        <div className="Donate__container__content">
          <img src={img1} alt="donate" />
          <div className="Donate__container__content__text">
            <p>
              Donate to the cause of the temple and help us in our mission to
              spread the message of Lord Krishna to the world. Your donations
              will be used for the development of the temple and for the welfare
              of the society.
            </p>
            <p>
              Donations can be made in the form of cash, cheque, demand draft,
              online transfer, etc. All donations are exempted from income tax
              under section 80G of the Income Tax Act, 1961.
            </p>
            <p>
              For more information, please contact us at{" "}
              <a href="mailto:niyamsewa@gmail.com"> niyamsewa@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
      <Footer/> 
    </div>
  );
};

export default Donate;
