import React, { useState, useEffect } from "react";
import logo from "../Images/new-logo-niyam-sewa-removebg-preview.png";

const LoadingScreen = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate a delay to showcase the loading screen.
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay time as needed.
  }, []);

  // Center the logo using CSS styles
  const centerLogoStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  if (isLoading) {
    return (
      <div className="loading-screen" style={centerLogoStyles}>
        <img src={logo} alt="" width="100" height="100" />
      </div>
    );
  } else {
    return null; // Return null when loading is complete, so it won't be visible anymore.
  }
};

export default LoadingScreen;
