import React from "react";
import { Link } from "react-router-dom"; // Assuming you are using React Router for navigation

const Donate = () => {
  const buttonStyle = {
    backgroundColor: "#FBD784", // Gold color
    color: "#000", // Black text color
    border: "none",
    padding: "10px 20px", // Adjust padding as needed
    fontSize: "16px",
    borderRadius: "5px", // Rounded corners
    cursor: "pointer",
  };

  return (
    <div className="Donate-btn">
      {/* Your other content */}
      <Link to="/donateus" style={{ textDecoration: "none" }}>
        <button style={buttonStyle}>
          <span style={{ fontWeight: "bold" }}>Donate Us</span>
        </button>
      </Link>
    </div>
  );
};

export default Donate;
