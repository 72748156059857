import React from "react";
import landing1 from "../Landing/Images/IMG_20181019_193531.jpg"; // Import the image
import { Link } from "react-router-dom";
import arrow from "./arrow_downward_24px.svg"
import "./Ourstory.css"
import img1 from "./Images/image-1.JPG"
import img2 from "./Images/img1.JPG"
import img3 from "./Images/IMG_0044.JPG"

const Ourstory = () => {
  return (
    <div className="our-story pt-5">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
          </div>
          <div className="col-lg-6 pb-4">
            <div
              className="Tagline pb-2"
              style={{ width: 288, height: 25, position: "relative" }}
            >
              <div
                className="KingOfFort pd-5"
                style={{
                  left: 96,
                  top: 0,
                  position: "absolute",
                  color: "#FBD784",
                  fontSize: 18,

                  fontWeight: "700",
                  textTransform: "uppercase",
                  letterSpacing: 6,
                  wordWrap: "break-word",
                }}
              >
                OUR STORY
              </div>
              <div
                className="Line"
                style={{
                  width: 72,
                  height: 2,
                  left: 0,
                  top: 10,
                  position: "absolute",
                  background: "#FBD784",
                }}
              />
            </div>

            <p className="pt-5 mt-3 text-white font-weight-normal">
              We are here to arrange
              all the possible services according to your convince and
              aspirations. The Name itself ‘Niyam Sewa’ suggests that Niyam
              means routine and there shouldn’t be any boundation or hurdle in
              the routine of serving the supreme
            </p>
            <div className="text-center">
              {" "}
              {/* Wrap the button in a div with text-center class */}
              
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-6 pb-4 order-lg-last">
            <img
              src={img1}
              alt="Our service"
              className="img-fluid rounded"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 pb-4 order-lg-first">
            <div
              className="Tagline pb-4"
              style={{ width: 288, height: 25, position: "relative" }}
            >
              <div
                className="KingOfFort"
                style={{
                  left: 96,
                  top: 0,
                  position: "absolute",
                  color: "#FBD784",
                  fontSize: 18,
                  fontWeight: "700",
                  textTransform: "uppercase",
                  letterSpacing: 6,
                  wordWrap: "break-word",
                }}
              >
                Shri Padma Lochan Goswami
              </div>
              <div
                className="Line"
                style={{
                  width: 72,
                  height: 2,
                  left: 0,
                  top: 10,
                  position: "absolute",
                  background: "#FBD784",
                }}
              />
            </div>

            <p className="pt-5 mt-4 text-white font-weight-normal ">
              Acharya Shri Padma Lochan Goswami is the 13th in the guru lineage
              of Shri Chaitanya Mahaprabhu himself . Belonging to the land of
              vraja and shri Radharaman lal , he has dedicated all his life in
              serving the eternal Vrindavan and Shri Radharaman lal .
            </p>
            <div className="text-center">
              {" "}
              {/* Wrap the button in a div with text-center class */}
              
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <img src={img2} alt="Our Story" className="img-fluid rounded" />
          </div>
          <div className="col-lg-6 pb-4">
            <div
              className="Tagline pb-2"
              style={{ width: 288, height: 25, position: "relative" }}
            >
              <div
                className="KingOfFort pd-5"
                style={{
                  left: 96,
                  top: 0,
                  position: "absolute",
                  color: "#FBD784",
                  fontSize: 18,

                  fontWeight: "700",
                  textTransform: "uppercase",
                  letterSpacing: 6,
                  wordWrap: "break-word",
                }}
              >
                SHRI DHANANJAY GOSWAMI
              </div>
              <div
                className="Line"
                style={{
                  width: 72,
                  height: 2,
                  left: 0,
                  top: 10,
                  position: "absolute",
                  background: "#FBD784",
                }}
              />
            </div>

            <p className="pt-5 mt-3 text-white font-weight-normal">
              Aacharya Shri Dhananjay Goswami , the 14th in the guru lineage of
              Shri Chaitanya mahaprabhu . Walking on the path carved by the
              ancestors with all his heart and devotion . It’s indeed a blessing
              to serve the supreme lord , the vraja and the needy .
            </p>
            <div className="text-center">
              {" "}
              {/* Wrap the button in a div with text-center class */}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourstory;
