import React from "react";
import Footer from "../../components/Footer/Footer";
import img1 from "./IMG-20220105-WA0035.jpg";
import "./Activity.css";
import img2 from "./IMG_0972.png"
import img3 from "./IMG_5791.JPG"
import img4 from './IMG-20220610-WA0005.jpg'
import img5 from "./IMG_0003.JPG"
import img6 from "./IMG_9652.JPG"
import img7 from "./IMG_9710.JPG"
const Activity = () => {
  return (
    <div className="activity">
      <h1>Activity</h1>

      <div className="activity__container container ">
        <div className="activity__container__top ">
          <div className="activity__container__top__left">
            <p className="activity__container__top__left__para">
              Niyamsewa Trust started several programs in the Holy Brij Kshetra
              in past few years and we are happy to show these amazing
              activities conducted by the time. Niyamsewa came up as a helping
              hand for the devotees. Our Trust is working progressively to
              provide basic aids to the needy ones at it’s utmost. Programs like
              ‘Food For Brij’, ‘Niyamsewa Healthcare are running immensely to
              fullfill the necessities of those who are unable to make it on
              their own.
            </p>
          </div>
          <div className="activity__container__top__right">
            <img src={img1} alt="img1" />
          </div>
        </div>
        <div className="activity__container__list__container">
          <div className="activity__container__list__container__list">
            <div className="activity__container__list__container__list__item">
              {/* List */}
              <ul
                className="  
              activity__container__list__container__list__item__list"
              >
                <li>Niyamsewa Healthcare</li>
                <li>Food For Brij</li>
                <li>Food and fodder distribution</li>
                <li>Blanket distribution</li>
                <li>Caps and socks distribution</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="activity__container__healthcare container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <img src={img2} alt="healthcare" className="img-fluid rounded" />
            </div>
            <div className="col-lg-6 pb-4">
              <div
                className="Tagline pb-2"
                style={{ width: 288, height: 25, position: "relative" }}
              >
                <div
                  className="KingOfFort"
                  style={{
                    left: 96,
                    top: 0,
                    position: "absolute",
                    color: "#FBD784",
                    fontSize: 18,

                    fontWeight: "700",
                    textTransform: "uppercase",
                    letterSpacing: 6,
                    wordWrap: "break-word",
                  }}
                >
                  NIYAMSEWA HEALTHCARE
                </div>
                <div
                  className="Line"
                  style={{
                    width: 72,
                    height: 2,
                    left: 0,
                    top: 10,
                    position: "absolute",
                    background: "#FBD784",
                  }}
                />
              </div>

              <p className="pt-5 text-white font-weight-normal">
                Welcome to Niyamsewa Healthcare, a compassionate initiative by
                Niyamsewa Trust dedicated to providing free medical facilities
                and essential aid to the needy in the Holy Brij Kshetra. Our
                trust believes in lending a helping hand to those who are less
                fortunate and ensuring that basic necessities are fulfilled for
                all.
              </p>
            </div>
          </div>
        </div>
        <div className="activity__container__Brij">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-lg-6 pb-4 order-lg-last">
                <img
                  src={img3}
                  alt="Our service"
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-lg-6 pb-4 order-lg-first">
                <div
                  className="Tagline pb-2"
                  style={{ width: 288, height: 25, position: "relative" }}
                >
                  <div
                    className="KingOfFort"
                    style={{
                      left: 96,
                      top: 0,
                      position: "absolute",
                      color: "#FBD784",
                      fontSize: 18,
                      fontWeight: "700",
                      textTransform: "uppercase",
                      letterSpacing: 6,
                      wordWrap: "break-word",
                    }}
                  >
                    Food For Brij
                  </div>
                  <div
                    className="Line"
                    style={{
                      width: 72,
                      height: 2,
                      left: 0,
                      top: 10,
                      position: "absolute",
                      background: "#FBD784",
                    }}
                  />
                </div>

                <p className="pt-5 text-white font-weight-normal">
                  The 'Food for Brij' program is our heartfelt endeavor to
                  alleviate hunger and provide relief to the destitute living on
                  the streets. We distribute healthy and delicious meals to
                  those in need, giving them a respite from the unbearable pangs
                  of hunger. We firmly believe that no one should sleep with an
                  empty stomach, and our mission is to bring a smile to the
                  faces of those we serve through the simple act of providing a
                  nutritious meal.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="activity__container__fooder container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <img src={img4} alt="Our Story" className="img-fluid rounded" />
            </div>
            <div className="col-lg-6 pb-4">
              <div
                className="Tagline pb-2"
                style={{ width: 288, height: 25, position: "relative" }}
              >
                <div
                  className="KingOfFort"
                  style={{
                    left: 96,
                    top: 0,
                    position: "absolute",
                    color: "#FBD784",
                    fontSize: 18,

                    fontWeight: "700",
                    textTransform: "uppercase",
                    letterSpacing: 6,
                    wordWrap: "break-word",
                  }}
                >
                  Food and Fooder distribution
                </div>
                <div
                  className="Line"
                  style={{
                    width: 72,
                    height: 2,
                    left: 0,
                    top: 10,
                    position: "absolute",
                    background: "#FBD784",
                  }}
                />
              </div>

              <p className="pt-5 text-white font-weight-normal">
                In our Food Distribution program, we strive to combat hunger by
                providing nourishing meals to those who are less fortunate and
                struggling to meet their basic nutritional requirements. Our
                team of dedicated volunteers works diligently to prepare and
                distribute healthy and delicious food to individuals living on
                the streets and facing food insecurity. We believe that everyone
                deserves access to a hearty meal and aim to bring relief and
                comfort to those in need.
              </p>
            </div>
          </div>
        </div>
        <div className="activity__container__blanket">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-lg-6 pb-4 order-lg-last">
                <img
                  src={img5}
                  alt="Our service"
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-lg-6 pb-4 order-lg-first">
                <div
                  className="Tagline pb-2"
                  style={{ width: 288, height: 25, position: "relative" }}
                >
                  <div
                    className="KingOfFort"
                    style={{
                      left: 96,
                      top: 0,
                      position: "absolute",
                      color: "#FBD784",
                      fontSize: 18,
                      fontWeight: "700",
                      textTransform: "uppercase",
                      letterSpacing: 6,
                      wordWrap: "break-word",
                    }}
                  >
                    Blanket Distribution
                  </div>
                  <div
                    className="Line"
                    style={{
                      width: 72,
                      height: 2,
                      left: 0,
                      top: 10,
                      position: "absolute",
                      background: "#FBD784",
                    }}
                  />
                </div>

                <p className="pt-5 text-white font-weight-normal">
                  With winters often being harsh in the region, we understand
                  the importance of staying warm and comfortable. Through our
                  'Blanket Distribution' program, we provide blankets to the
                  homeless and vulnerable individuals, helping them cope with
                  the chilling weather and ensuring their well-being during the
                  colder months.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="activity__container__distribution container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <img src={img1} alt="Our Story" className="img-fluid rounded" />
            </div>
            <div className="col-lg-6 pb-5">
              <div
                className="Tagline pb-3"
                style={{ width: 288, height: 25, position: "relative" }}
              >
                <div
                  className="KingOfFort pd-3"
                  style={{
                    left: 96,
                    top: 0,
                    position: "absolute",
                    color: "#FBD784",
                    fontSize: 18,

                    fontWeight: "700",
                    textTransform: "uppercase",
                    letterSpacing: 6,
                    wordWrap: "break-word",
                  }}
                >
                  Caps and socks distribution
                </div>
                <div
                  className="Line"
                  style={{
                    width: 72,
                    height: 2,
                    left: 0,
                    top: 10,
                    position: "absolute",
                    background: "#FBD784",
                  }}
                />
              </div>

              <p className="pt-5 mt-5 text-white font-weight-normal">
                Small gestures can make a big difference in the lives of those
                struggling to meet even their basic needs. In our 'Caps and
                Socks Distribution' program, we provide warm caps and socks to
                those in need. These seemingly small items can significantly
                improve their comfort and protect them from the elements.
              </p>
            </div>
          </div>
        </div>
        <div className="activity__container__blanket">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-lg-6 pb-4 order-lg-last">
                <img
                  src={img6}
                  alt="Our service"
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-lg-6 pb-4 order-lg-first">
                <div
                  className="Tagline pb-2"
                  style={{ width: 288, height: 25, position: "relative" }}
                >
                  <div
                    className="KingOfFort"
                    style={{
                      left: 96,
                      top: 0,
                      position: "absolute",
                      color: "#FBD784",
                      fontSize: 18,
                      fontWeight: "700",
                      textTransform: "uppercase",
                      letterSpacing: 6,
                      wordWrap: "break-word",
                    }}
                  >
                    Chai And Biscuit Distribution
                  </div>
                  <div
                    className="Line"
                    style={{
                      width: 72,
                      height: 2,
                      left: 0,
                      top: 10,
                      position: "absolute",
                      background: "#FBD784",
                    }}
                  />
                </div>

                
              </div>
            </div>
          </div>
        </div>
        <div className="activity__container__distribution container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <img src={img7} alt="Our Story" className="img-fluid rounded" />
            </div>
            <div className="col-lg-6 pb-5">
              <div
                className="Tagline pb-3"
                style={{ width: 288, height: 25, position: "relative" }}
              >
                <div
                  className="KingOfFort pd-3"
                  style={{
                    left: 96,
                    top: 0,
                    position: "absolute",
                    color: "#FBD784",
                    fontSize: 18,

                    fontWeight: "700",
                    textTransform: "uppercase",
                    letterSpacing: 6,
                    wordWrap: "break-word",
                  }}
                >
                  Deep Daan
                </div>
                <div
                  className="Line"
                  style={{
                    width: 72,
                    height: 2,
                    left: 0,
                    top: 10,
                    position: "absolute",
                    background: "#FBD784",
                  }}
                />
              </div>

             
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Activity;
