import React, { useEffect, useState } from "react";
import backgroundImage from "./FB_IMG_1644088148080.jpg"; // Import your background image here
import backgroundImage_mobile from "./20230624_18594.jpg"; // Import your background image here
import Ourstory from "../../components/Our Story/Ourstory";
import Ourservices from "../../components/Ourservices/Ourservices";
import Landing from "../../components/Landing/Landing";
import Niyamsewa from "../../components/Niyamsewa/Niyamsewa";
import Footer from "../../components/Footer/Footer";
import Gallery from "../../components/Gallery/Gallery";
import RazorpayButton from "../../components/Razorpay/Razorpay";
import "./Home.css";

const Home = () => {
  const [backgroundImageSrc, setBackgroundImageSrc] = useState(backgroundImage);

 

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImageSrc})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    fill: "linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)",
    strokeWidth: "1px",
    stroke: "#000",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    height: "1080px",
    boxShadow: "inset 10px 0 100px 10px #000000",
    backgroundBlendMode: "screen",
  };

  return (
    <>
      <Landing />
      <div className="main" style={backgroundStyle}>
        {/* Content of your website */}

        <div style={{ paddingTop: "962px" }}>
          {/* Add padding to the top to move the content down */}
          {/* <Niyamsewa/> */}

          <Ourstory />
          <Ourservices />
          <Gallery />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
