import React, { useEffect, useState } from "react";

export default function RazorpayButtonp() {
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    const rzpPaymentForm = document.getElementById("rzp_payment_form");

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_MJupmLkO07Ilwf";
      rzpPaymentForm.appendChild(script);
    }

    // Event listener for payment success
    const handlePaymentSuccess = (event) => {
      // Perform any actions you need to do when the payment is successful
      // For example, set the paymentSuccess state to true
      setPaymentSuccess(true);
    };

    window.addEventListener("payment.success", handlePaymentSuccess);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("payment.success", handlePaymentSuccess);
    };
  }, []);

  return (
    <div className="App">
      {/* Display a thank you message if payment is successful */}
      {paymentSuccess ? (
        <div>
          <h2>Thank you for the donation!</h2>
          <p>Your payment was successful.</p>
        </div>
      ) : (
        <form id="rzp_payment_form"></form>
      )}
    </div>
  );
}
