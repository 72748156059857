import React, { lazy, Suspense } from "react";

const LazyOffering = lazy(() => import("./Home"));

const HomeLazyLoader = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <LazyOffering />
  </Suspense>
);

export default HomeLazyLoader;
