import React from "react";
import "./Landing.css"; // Import the CSS file
import { Link } from "react-router-dom";
import RazorpayButtonp from "../Razorpay/Razorpay";
import Donate from "../Donate/Donate";
const Landing = () => {
  return (
    <div className="landing_div">
      <div className="button">
        <Donate/>
      </div>
      <div className="Group17">
        <div className="Rectangle21" />
        <div className="QueenOfTheDeccan">The Routine Of Soul</div>
      </div>

      <div className="WelcomeNiyamsewa"></div>
    </div>
  );
};

export default Landing;
