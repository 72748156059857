import React, { useState } from "react";
import "./Contact.css";
import { FaFile, FaPhone, FaWhatsapp, FaEnvelope } from "react-icons/fa";

function Contact() {
  const [showSocialIcons, setShowSocialIcons] = useState(false);

  const toggleSocialIcons = (e) => {
    e.preventDefault();
    setShowSocialIcons(!showSocialIcons);
  };

  return (
    <>
      <div className="wrapper">
        <a
          className={`social ${showSocialIcons ? "show" : ""}`}
          href="mailto:niyamsewa@gmail.com"
        >
          <FaEnvelope />
        </a>
        <a
          className={`social ${showSocialIcons ? "show" : ""}`}
          href="tel:+91 7827266248"
        >
          <FaPhone />
        </a>
        <a
          className={`social ${showSocialIcons ? "show" : ""}`}
          href="https://wa.me/+917827266248"
        >
          <FaWhatsapp />
        </a>
        <a
          className={`close-button ${
            showSocialIcons ? "close-button-rotate" : ""
          }`}
          onClick={toggleSocialIcons}
        >
          <FaPhone />
        </a>
      </div>
    </>
  );
}

export default Contact;
