import React, { lazy, Suspense } from "react";

const LazyActivity = lazy(() => import("./Activity"));

const ActivityLazyLoader = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <LazyActivity />
  </Suspense>
);

export default ActivityLazyLoader;
