import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Gallery.css"; // Import custom CSS file for styling
import img1 from "./Images/img1 (1).jpg"
import img2 from "./Images/img1 (2).jpg"
import img3 from "./Images/img1 (3).jpg"
import img4 from "./Images/img1 (4).jpg"
import img5 from "./Images/img1 (5).jpg"
import img6 from "./Images/img1 (6).jpg"
import img7 from "./Images/img1 (7).JPG"
import img8 from "./Images/img1 (8).JPG"
import img9 from "./Images/img1 (9).JPG"
import img10 from "./Images/img1 (10).JPG"
import img11 from "./Images/img1 (11).JPG"
import img12 from "./Images/img1 (12).jpg"
import img13 from "./Images/img1 (13).jpg"
import img14 from "./Images/img1 (14).jpg"
import img15 from "./Images/img1 (15).jpg"
import img16 from "./Images/img1 (16).jpg"
import img17 from "./Images/img1 (17).jpg"
import img18 from "./Images/img2 (1).jpg"
import img19 from "./Images/img2 (2).jpg"
import img20 from "./Images/img2 (3).jpg"
import img21 from "./Images/img2 (4).jpg"
import img22 from "./Images/img2 (5).JPG"
import img23 from "./Images/img2 (6).jpg"
import img24 from "./Images/img2 (7).JPG"
import img25 from "./Images/img2 (8).JPG"
import img26 from "./Images/img2 (9).JPG"
import img27 from "./Images/img2 (10).jpg"
import img28 from "./Images/img2 (11).jpg"
import img29 from "./Images/img2 (12).jpg"
import img30 from "./Images/img2 (13).jpg"
import img31 from "./Images/img2 (14).jpg"
import img32 from "./Images/img2 (15).jpg"




const images = [img1,img2 ,img3 ,img4 ,img5 ,img6 ,img7 ,img8 ,img9 ,img10 ,img11 ,img12 ,img13 ,img14 ,img15 ,img16 ,img17
  ,img18 ,img19 ,img20 ,img21 ,img22 ,img23 ,img24 ,img25 ,img26 ,img27 ,img28 ,img29 ,img30 ,img31 ,img32];

const SponsorCard = ({ img }) => {
  // Change function name to follow conventions
  return (
    <div className="card p-3 sponsor-card">
      {" "}
      {/* Added custom CSS class */}
      <img className="card-img" src={img} loading="lazy" alt="" />
    </div>
  );
};

const Gallery = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    swipeToSlide: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="pc">
      <div className="pc-container d-flex align-items-center justify-content-center flex-column sponsor-container mt-5">
        <h1 className="heading mb-4">Gallery</h1>
        <div className="row justify-content-center">
          {images.map((element, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-12 mb-3">
              <SponsorCard img={element} />
            </div>
          ))}
        </div>
      </div>
      <div className="container mobile-container sponsor-container my-5">
        <h1 className="heading mb-4">Gallery</h1>
        <div className="container">
          <Slider {...settings}>
            {images.map((element, index) => (
              <div key={index}>
                <SponsorCard img={element} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
