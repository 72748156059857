import React, { useState } from "react";
import logo from "./logo1.png";
import "./NavBar.css";
import { Link } from "react-router-dom";
import RazorpayButton from "../Razorpay/Razorpay";
const NavBar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setIsNavbarOpen((prev) => !prev);
  };
  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${
          isNavbarOpen ? "expanded" : "collapsed"
        }`}
      >
        <div className="container-fluid">
          <div className="Niyamsewa">
          <img src={logo} alt="logo" className="logo" />
          </div>
          

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            color="white"
            onClick={handleNavbarToggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="32"
              width="32"
              viewBox="0 0 512 512"
              style={{ fill: "white" }}
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM64 256c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H96c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
            </svg>
          </button>

          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <div className="navbar-nav">
              <a className="nav-link  " aria-current="page" href="/">
                Home
              </a>
              <a className="nav-link" href="/offering">
                Our Offerings
              </a>
              <a className="nav-link" href="/radha-raman">
                Shri Radha Raman Temple
              </a>
              <a className="nav-link" href="/mahaprabhu">
                Shri Chaitanya Mahaprabhu
              </a>
              <a className="nav-link" href="/vrindavan">
                Vrindhavan / Yamuna JI
              </a>

              <a className="nav-link" href="/activity">
                Our Activities
              </a>
            </div>
          </div>
          {/* <Link to="/donate" className="donation btn text-white ">
            Donate Us
          </Link> */}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
