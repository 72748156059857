import React from "react";
import img1 from "./IMG-20220610-WA0005.jpg";
import "./Offering.css";
import Footer from "../../components/Footer/Footer";
import img_g_1 from "./images/img1 (1).JPG"
import img_g_2 from "./images/img1 (2).JPG"
import img_g_3 from "./images/img1 (3).JPG"
import img_g_4 from "./images/img1 (4).JPG"
import img_g_5 from "./images/img1 (5).JPG"
import img_g_6 from "./images/img1 (6).JPG"
import img_g_7 from "./images/img1 (7).JPG"
import img_g_8 from "./images/FB_IMG_1656605666362.jpg"
import img_g_9 from "./images/IMG_0027.JPG"
import img_g_10 from "./images/IMG-20220429-WA0025.jpg"
import img_g_11 from "./images/img1 (11).jpg"
import img_g_12 from "./images/IMG-20220610-WA0028.jpg"
import img_g_13 from "./images/IMG-20220610-WA0005.jpg"
import { Link } from "react-router-dom";
import img_g_14 from "./images/IMG-20220413-WA0006.jpg"
import RazorpayButton from "../../components/Razorpay/Razorpay";
const OfferingCard = ({ img, title }) => {
  return (
    <div className="col-md-4 py-3 ">
      <div className="card transparent-bg">
        <img src={img} className="card-img-top" alt="" />
        <div className="card-body text-white">
          <h5 className="card-title">{title}</h5>
        </div>
      </div>
    </div>
  );
};

const Offering = () => {
  return (
    <div className="offering container mt-5">
      <h1>Our Offerings</h1>
      <div className="offering__para mx-3 text-white">
        Sevaarpan in the lotus feet of Shri Radharaman Dev ji is the highest we
        can do in order to achieve his mercy and salvation. From serving the
        temples including shri Radharaman ji, Amiya Nimai Maharprabhu ji to
        performing different sevas for the devotees and sadhus. Niyamsewa is
        working day and night to help the people in any way possible. Whether it
        is food, cloth, health care or the religious aspects, Sewa is the only
        focus. Those who wish to be a part of our philanthropic activities can
        contribute here.
      </div>
      <RazorpayButton />

      <div className="row row-1 container">
        <OfferingCard img={img_g_4} title="Milk Abhishek Offering " />
        <OfferingCard img={img_g_2} title="Ghee Abhishek Offering" />
        <OfferingCard img={img_g_8} title="Flower decoration (Phool Bangla)" />
        <OfferingCard img={img_g_13} title="Kuliya Offering" />
        <OfferingCard img={img_g_12} title="Fruits Offering" />
        <OfferingCard img={img_g_1} title="Shri Radharaman Ji’s Dress" />
        <OfferingCard img={img_g_11} title="56 Bhog Offering" />
        <OfferingCard img={img_g_9} title="Sadhu Sewa" />
        <OfferingCard
          img={img_g_5}
          title="Distribution of Socks and cap in winter"
        />
        <OfferingCard
          img={img_g_7}
          title="Distribution of basic amenities like Flour, oil, rice and vegetables"
        />
        <OfferingCard img={img_g_14} title="Feast for widows" />
        <OfferingCard
          img={img_g_6}
          title="Deep daan offering at temples and shri Yamuna ji"
        />
        <OfferingCard img={img_g_10} title="Food distribution" />
        <OfferingCard img={img_g_3} title="Tulsi Sewa" />
      </div>
      <Footer />
    </div>
  );
};

export default Offering;
