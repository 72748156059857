import React from "react";
import Footer from "../../components/Footer/Footer";
import img1 from "./img1 (1).JPG";
import "./Radha.css";
import img2 from "./IMG_20181019_193531.jpg"
const Radha = () => {
  return (
    <div className="radha container">
      <div className="radha__header">
        <h1>Shri Radha Raman Temple</h1>
      </div>
      <div className="radha__content">
        <div className="radha__content__left">
          <div className="radha__content__left__image">
            <img src={img1} alt="radha" />
          </div>
          <div className="radha__content__left__text text-white">
            <p className="radha__content__para text-white">
              Shri Gopal Bhatt Goswami was only ten years old when Mahaprabhu
              stayed for four months at his family home in Shri Rangam, during
              Mahaprabhu’s pilgrimage through South India. Gopal Bhatt dedicated
              himself completely to Mahaprabhu’s service, caring for his every
              need, whilst drinking the nectar of his teachings, which where
              full of love for Krishn. One day, during a conversation with Gopal
              Bhatt’s father, Venkat Bhatt, Chaitanya Mahaprabhu said, “Do not
              take Gopal to be a normal human being. He is an incarnation of a
              gopi, a personal associate of Radha and Krishn. With time, he will
              help millions of afflicted souls.” Venkat Bhatt offered his son at
              the lotus feet of Mahaprabhu, who initiated him on Kartik Shukla
              Ekadashi of that year .
            </p>
            <p className="radha__content__para">
              Together they rediscovered the hidden places of Radha and Krishn’s
              pastimes and wrote the devotional texts, such as Hari Bhakti
              Vilas, Tattva, Paramatma, Bhagavat, Krishn, Bhakti, Priti
              Sandarbhas, Krishn Vallabh’s commentary on Krishn Karnamrit, which
              now define Gaudiya Vaishnav philosophy. One night, Shri Roop and
              Sanatan both dreamt that Mahaprabhu infused Gopal Bhatt with the
              light of his essence and left for his eternal abode. Soon after,
              news of Chaitanya Mahaprabhu’s departure from this world was
              received and each grieving devotee saw a vision of Mahaprabhu. He
              told them, “Roop and Sanatan are my personal associates and they
              should serve Govind Dev and Madan-Mohan. And my life, my dearmost
              Gopal Bhatt should preach the path of Bhakti and go to Gandaki
              River in Nepal, where he will receive twelve shaligram shilas. Of
              these shilas, I will always reside in the Damodar shaligram shila.
              He shall worship me by worshipping that shila [which later became
              the beautiful form of Shri Radharaman].
              <div className="radha__content__left__image py-3">
                <img src={img2} alt="radha" />
              </div>
              My wooden seat, which I have given to Gopal Bhatt, is the throne
              upon which he will sit as the next guru of Gaudiya Vaishnavism,
              and preach devotion for Radha and Krishn. This seat is symbolic of
              my authority, and will be handed down from generation to
              generation of Goswamis who are eligible to become gurus within the
              lineage. After the necessary rituals were performed, Gopal Bhatt
              sat on the wooden seat. From this auspicious day, he was honoured
              with the title of Goswami, and the lineage of acharyas established
              by him use Goswami as their surname. Gopal Bhatt chose his
              disciple Damodar Das Goswami as his successor, and by his
              blessings, Damodar Das Goswami and his descendants would serve the
              deity of Shri Radharaman, guiding devotees as scholars and
              spiritual teachers for all generations. Over the last 500 years
              until the present day, the Goswamis of the Shri Radharaman Temple
              have been following Mahaprabhu’s order and Gopal Bhatt Goswami’s
              instructions, dedicating their lives for the purpose of preaching
              devotion in the world and worshipping Shri Radharaman.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Radha;
