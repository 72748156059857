import React, { useState } from "react";
import "./Footer.css";
import logo from "../Images/new-logo-niyam-sewa-removebg-preview.png";
import smallImage from "../Images/new-logo-niyam-sewa-removebg-preview.png";
import { Link } from "react-router-dom";
import {FaFacebook, FaInstagram, FaYoutube} from "react-icons/fa"
import RazorpayButton from "../Razorpay/Razorpay";
import RazorpayButtonp from "../Razorpay/Razorpay";
import Donate from "../Donate/Donate";
const Footer = () => {
  const [showForm, setShowForm] = useState(false);

  const handleButtonClick = () => {
    <form>
      <script
        src="https://checkout.razorpay.com/v1/payment-button.js"
        data-payment_button_id="pl_MJupmLkO07Ilwf"
        async
      >
        {" "}
      </script>{" "}
    </form>;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Process the form data here
    // You can use form submission to send data to your backend or handle it in any way you prefer
  };

  return (
    <div className="footer ">
      <div className="footer__container">
        <div className="footer__top pb-5">
          <div className="footer__top__left">
            <div className="Niyamsewa">NIYAMSEWA</div>
          </div>
          <div className="footer__top__right">
            <div>
              
              <Donate/>
              </div>
          </div>
        </div>
        <div
          className="Divider"
          style={{
            height: 1.38,
            opacity: 0.3,
            background: "white",
          }}
        />

        <div className="footer__middle pt-5">
          <div className="row">
            <div className="col-md-4">
              <p className="footer__middle__left__heading color-white">
                The Routine <br /> of SOUL
              </p>
            </div>
            <div className="col-md-4">
              <p className="footer__middle__center__heading">Contact Us</p>
              <p className="footer__middle__center__text color-white">
                <span className="footer__middle__center__text__bold color-white">
                  Address:
                </span>{" "}
                Radha Raman Temple , Vaishnav khand , Vrindavan , Mathura 281121
                (U.P)
              </p>
              <p className="footer__middle__center__text">
                <span className="footer__middle__center__text__bold">
                  Phone:
                </span>{" "}
                +91 7827266248
              </p>
              <p className="footer__middle__center__text">
                <span className="footer__middle__center__text__bold">
                  Email:
                </span>{" "}
                <a
                  href="mailto:niyamsewa@gmail.com"
                  className="footer__middle__center__text__email text-white"
                >
                  niyamsewa@gmail.com
                </a>
              </p>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-between">
                <div className="footer__middle__right__coloumn1">
                  <p className="footer__middle__right__heading">Quick Links</p>

                  <Link to="/">
                    <p className="footer__middle__right__text">Home</p>
                  </Link>
                  <Link to="/activity">
                    <p className="footer__middle__right__text">
                      Our Activities
                    </p>
                  </Link>
                  <Link to="/offering">
                    <p className="footer__middle__right__text">Our Offerings</p>
                  </Link>
                  <Link to="/radha-raman">
                    <p className="footer__middle__right__text">
                      Shri Radha Ramanji
                    </p>
                  </Link>
                  <Link to="/mahaprabhu">
                    <p className="footer__middle__right__text">
                      Shri Chaitanya Mahaprabhu
                    </p>
                  </Link>
                  <Link to="/vrindavan">
                    <p className="footer__middle__right__text">
                      Vrindavan / Yamuna JI
                    </p>
                  </Link>
                </div>
                <div className="footer__middle__right__coloumn2">
                  <p className="footer__middle__right__heading">Help</p>

                  <p className="footer__middle__right__text">FAQ</p>
                  <Link to="https://merchant.razorpay.com/policy/MJi4oGvGwz3FbQ/contact_us">
                    <p className="footer__middle__right__text">Contact Us</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__bottom__left">
            <p className="footer__bottom__left__text text-white ">
              <Link
                to="https://merchant.razorpay.com/policy/MJi4oGvGwz3FbQ/privacy"
                className="text-white"
              >
                Privacy Policy
              </Link>
              <span className="footer__bottom__left__text__divider px-5">
                |
              </span>
              <Link
                to="https://merchant.razorpay.com/policy/MJi4oGvGwz3FbQ/terms"
                className="text-white"
              >
                Terms & Conditions
              </Link>
              <span className="footer__bottom__left__text__divider px-5">
                |
              </span>
              <Link
                to="https://merchant.razorpay.com/policy/MJi4oGvGwz3FbQ/refund"
                className="text-white"
              >
                Cancellation & Refund Policy
              </Link>
              <span className="footer__bottom__left__text__divider px-5">
                |
              </span>

              <Link
                to="https://merchant.razorpay.com/policy/MJi4oGvGwz3FbQ/shipping"
                className="text-white"
              >
                Shipping & Delivery Policy
              </Link>
            </p>
          </div>
          <div className="footer__bottom__right">
            <p className="footer__bottom__right__social-links">
              <a href="https://www.facebook.com/niyamsewa" target="_blank">
                <FaFacebook />
              </a>
              <a
                href="https://www.instagram.com/niyamsewa/?hl=en"
                target="_blank"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.youtube.com/channel/UCkv9WD4mKRDHVHZjXxtxkNQ"
                target="_blank"
              >
                <FaYoutube />
              </a>
            </p>
          </div>
        </div>
        <div className="footer__bottom__bottom">
          <p className="footer__bottom__bottom__text text-white text-center">
            © 2023 Niyamsewa. All Rights Reserved.
          </p>
          <p className="footer__bottom__bottom__text text-white text-center">
            Designed & Developed by {"DesignDex "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
