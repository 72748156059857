import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingScreen from "./components/Loading/LoadingScreen"; // Import the LoadingScreen component
import Footer from "./components/Footer/Footer";
import About from "./Pages/About/About";
import Activity from "./Pages/Activity/Activity";
import Offering from "./Pages/Our Offering/Offering";
import ActivityLazyLoader from "./Pages/Activity";
import OfferingLazyLoader from "./Pages/Our Offering";
import HomeLazyLoader from "./Pages/Home";
import Radha from "./Pages/Radha-Raman/Radha";
import Chaitnya from "./Pages/Chaitnya/Chaitnya";
import Vrindavan from "./Pages/Vrindavan/Vrindavan";
import Contact from "./components/contact/Contact";
import Donate from "./Pages/Donate/Donate";
function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate a delay to showcase the loading screen.
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay time as needed.
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <div className="">
        <Contact/>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomeLazyLoader/>} />
          <Route path="/activity" element={<ActivityLazyLoader/>}/>
          <Route path="/offering" element={<OfferingLazyLoader/>} />
          <Route path="/radha-raman" element={<Radha/>}/>
          <Route path="/mahaprabhu" element={<Chaitnya/>}/>
          <Route path="/vrindavan" element={<Vrindavan/>}/>
          <Route path="/DonateUs" element={<Donate/>}/>
        </Routes>
      </div>
      
    </Router>
  );
}

export default App;
