import React from "react";
import landing1 from "../Landing/Images/IMG_20181019_193531.jpg"; // Import the image
import { Link } from "react-router-dom";
import arrow from "./arrow_downward_24px.svg";
import img1 from "./IMG_4639.png"
const Ourservice = () => {
  return (
    <div className="our-service pt-5">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-6 pb-4 order-lg-last">
            <img
              src={img1}
              alt="Our service"
              className="img-fluid rounded"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 pb-4 order-lg-first">
            <div
              className="Tagline pb-2"
              style={{ width: 288, height: 25, position: "relative" }}
            >
              <div
                className="KingOfFort"
                style={{
                  left: 96,
                  top: 0,
                  position: "absolute",
                  color: "#FBD784",
                  fontSize: 18,
                  fontWeight: "700",
                  textTransform: "uppercase",
                  letterSpacing: 6,
                  wordWrap: "break-word",
                }}
              >
                Our Services
              </div>
              <div
                className="Line"
                style={{
                  width: 72,
                  height: 2,
                  left: 0,
                  top: 10,
                  position: "absolute",
                  background: "#FBD784",
                }}
              />
            </div>

            <p className="pt-5 text-white font-weight-normal">
              Niyamsewa is a non-profit organization based in Vrindavan, Uttar
              Pradesh, India. It was founded in 2003 by a group of volunteers
              who were concerned about the plight of the poor and marginalized
              in the region. The organization's mission is to "empower the poor
              and marginalized through education, health care, and social
              services."
            </p>
            <div className="text-center">
              {" "}
              {/* Wrap the button in a div with text-center class */}
              <Link to="/activity">
                <div
                  className="More"
                  style={{ width: 127, height: 25, position: "relative" }}
                >
                  <div
                    className="Equipment"
                    style={{
                      left: 0,
                      top: 0,
                      position: "absolute",
                      color: "#FBD784",
                      fontSize: 18,
                      fontWeight: "700",
                      wordWrap: "break-word",
                    }}
                  >
                    read more
                  </div>
                  <div
                    className="IconNavigationArrowDownward24px"
                    style={{
                      position: "absolute",
                      transformOrigin: "0 0",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "inline-flex",
                    }}
                  >
                    <div className="IconNavigationArrowDownward24px">
                      <img src={arrow} alt="arrow" className="arrow rotate" />
                    </div>
                  </div>
                </div>{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourservice;
